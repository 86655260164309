import { render, staticRenderFns } from "./modalVerInstrucciones.vue?vue&type=template&id=3b5c2a7e&scoped=true"
import script from "./modalVerInstrucciones.vue?vue&type=script&lang=js"
export * from "./modalVerInstrucciones.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b5c2a7e",
  null
  
)

export default component.exports